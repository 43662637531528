import { useContext } from 'react';

import { SendbirdSdkContext } from '../lib/SendbirdSdkContext';
import { SendBirdState } from '../index';

function useSendbirdStateContext (): SendBirdState {
  const context: SendBirdState = useContext(SendbirdSdkContext);
  return context;
}

export default useSendbirdStateContext;
