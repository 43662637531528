import React, { ReactElement } from 'react';
import { ClientAdminMessage } from '../../index';
import './index.scss';

import Label, { LabelColors, LabelTypography } from '../Label';

interface Props {
  message: ClientAdminMessage;
  className?: string | Array<string>;
}

export default function OpenChannelAdminMessage({
  message,
  className,
}: Props): ReactElement {
  const injectingClassName = Array.isArray(className) ? className : [className];
  injectingClassName.push('sendbird-openchannel-admin-message');

  return (
    <div className={[...injectingClassName].join(' ')}>
      <Label
        className="sendbird-openchannel-admin-message__text"
        type={LabelTypography.CAPTION_2}
        color={LabelColors.ONBACKGROUND_2}
      >
        {message.message || ''}
      </Label>
    </div>
  );
}
